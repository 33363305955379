.flyer-container{
    width: 100%;
    height: auto;
}

.image-wrappers{
    width: 100%;
    height: 75vh;
    border-radius: 10px;
    border: 3px solid #0172DA ;
    margin-top: 5vh;
    
}

.image-flyer{
    width: 100%;
     height: 100%;
    overflow: hidden;
    border-radius: 10PX;
   
}
.address{
    font-weight: bolder;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 7px;
    width: 100%;
    height: auto;
    margin: 5vh 0;
}
.about-container{
    width: 100%;
    height: auto;
    margin-top: 5vh;
  
}
.about-text-container{
    width: 100%;
    overflow-x: auto;
    height: 50vh;
    border: 1px solid #0172DA;
    border-radius: 10px;
    margin-top: 2vh;
    padding: 2vh;
}
.about-text{
    text-align: left;
    color: #007AFF;
    font-size: 12px;
}


.exco-detailssec{
    width:auto;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 40px 11px #EBEBEB;
    font-weight: bolder;
   
}

.exco-detailssec h6, .exco-details h6{
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    margin-top: 2vh;
    text-transform: lowercase;
    }

    .program-report{
        width: 100%;
        margin-top: 8vh;
    }
    .full-popup{
        position:absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
/* styles.css */
.container {
    perspective: 1000px;
    display: flex;
    gap: 20px;
   
  }
  
  .card {
    position: relative;
    width: 40vh;
    border-radius: 10px;
    height: 48vh; 
    transform-style: preserve-3d;
    transition: transform 0.5s;
    margin: 5vh;
  }
  
  .card.flipped {
    transform: rotateY(180deg);
  }
  
  .card .front, .card .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    font-size: 24px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
 
  
  .card .back {
    width: 100%;
    transform: rotateY(180deg);
  }
 
  .event-container{
    width: 100%;
    margin: 5vh 0vh 5vh 0vh;
  }
  .event-list{
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    padding: 20px 0px 20px 15px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin-top: 5vh;
    border-radius: 7px;
    transition: var(--base-duration) ease;

  }
  .event-button{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 19.5rem;
    margin-top: 4vh;
    padding: 1rem 3rem;
    background-color: var(--color-2);
    border: none;
    border-radius: 0.3125rem;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: 300;
    text-transform: uppercase;
    transition: var(--base-duration) ease;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    animation: fadeIn 3s forwards;
  }

  
  @keyframes fadeIn {
    to {
      opacity: 10;
    }
  }
    @media only screen and (max-width: 500px){
        .image-wrappers{
         height: 50vh;
        }
       .card{
        margin: 0;
       }
      
    }