/* Navbar.css */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    margin: 0;
    background-color: white;
    color: black;
    position: sticky;
    top: 0;
    z-index: 999;
    margin-top: 20px;
  }
  
  .navbar-logo img {
    height: 50px; /* Made the logo bigger */
   
  }
  
/* Navbar.css */

.navbar-right {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
  }
.navbar-drop {
   display: none;
  }
  
  .hamburger-menu {
    display: none;
    cursor: pointer;
  }
  
  .navbar-drop.open {
    display: none;
  }
  
  .navbar-drop.open + .hamburger-menu {
    display: block;
  }
  
  .hamburger-menu {
    display: none;
    flex-direction: column;
    align-items: center;
  }
  
  .hamburger-menu div {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
  }
  

  .navbar-link {
    
    color: #007aff;
    font-weight: 600;
    text-decoration: none;
  }
  
/* Style for the search input field */
.dropdown-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Style for the dropdown list */
.dropdown-container{
  position: absolute;
  width: 100%;
  height: auto; /* Adjust height as needed */
  top: 10vh;
  right: 0;
  display: flex;
  justify-content: center;
 z-index: 999;
}
.dropdown {
 
  width: 100%;
  height: auto;
  max-height: 300px; /* Maximum height of 300px */
  overflow-y: hidden; /* Enable vertical scrolling when exceeding max-height */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
 
}

/* Style for each list item */
.dropdown li {
  list-style: none;
  margin: 10px;
  padding: 0;
}

/* Style for each link in the dropdown */
.dropdown-university-list {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
}

/* Hover effect for dropdown items */
.dropdown-university-list:hover {
  background-color: #f1f1f1;
}

/* Highlighted item style */
.highlighted {
  background-color: lightblue; /* Change to your preferred highlight color */
}

/* Remove underline from anchor tags inside the dropdown */
.dropdown-university-list a {
  text-decoration: none;
  color: inherit;
}

  .regular-btn {
    background-color: white; /* Changed background color to white */
    color: blue;
    margin-left: 1.2vw;
    padding: 10px 20px;
    border: 1px solid blue; /* Added blue border */
    cursor: pointer;
    border-radius: 10px;
  }
  

  .link {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.nav{
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.current {
  border-radius: 10px;
  background: linear-gradient(90deg, #007aff, #2fe6f2, #599ce5, #cea3f9, #004999);
  width: 50px;
  height: 8px;
  margin-left: 0;
}
.nav-buttons{
  display: flex;
  width: 80%;
}

  @media (max-width: 800px) {
    .navbar-right {
      display: none;
    }
    
    
    .hamburger-menu {
      display: flex;
    }
  
    .navbar-drop.active {
      display: flex;
      
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      background-color: white;
      padding: 10px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    }

    
  }
  @media (max-width: 768px) {
    .nav-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .dropdown-btn{
    width: 100%;
  }
  .dropdown{
    width: 60%;
  }
  }
  @media (max-width: 500px) {
  
   
  .dropdown-btn{
    width: 100%;
    padding: 2px 10px 2px 10px;
    font-size: 12px;
    height: 35px;
  }
  .regular-btn{
    height: 35px;
    padding: 5px 5px;
    font-size: 10px;
    width: 70px;
  }
  .dropdown{
    width: 100%;
  right: 0;
   
  }


  .dropdown-university-list{
    font-size: 10px;
  }

  }


  .container {
    position: relative;
    width: min(100% , 70%);
    height: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #004999;
    padding: 3px;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
  }
  
  .container.hover {
    width: 50%;
    border: 1px solid #007aff;
  }
  
  @media (min-width: 768px) {
    .container.hover {
      width: 80%;
    }
  }
  
  .search-input {
   
    width: 100%;
    height: 100%;
    line-height: 30px;
    outline: 0;
    border: 0;
    font-size: 15px;
    border-radius: 10px;
    padding: 0 20px;
    margin: 0;
   
    
  }
  
  .search-input.show {
    display: block;
  }
  
  .icon-magnifying-glass,
  .icon-right-arrow {
    height: 1rem;
    width: 1rem;
    color: #007aff;
    z-index: 10;
    animation: fadeIn 1s linear;
  }
  
  .icon-right-arrow {
    cursor: pointer;
    margin-left: 20vh;
  }
  
  .icon-right-arrow:hover {
    color: #393e46;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  