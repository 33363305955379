:root{
    --gradiet-color: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
  }


.home-container{
   width: 100%;
   padding: 0vh 8vh 0vh 8vh;
   overflow: hidden;
   
}
.sub-home-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.top-home-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.hero-text-layout{
    width: 450px;
    height: 180px;
   
}
.hero-container{
    width: 100%;
    height: 70vh;
    display: flex;
    margin-top: 10vh;
    align-items: center;
}
.logos-container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 7px;
  width: 100%;
  height: auto;
  margin: 5vh 0vh 10vh 0vh;
  justify-items: center;
  align-items: center;
}

.logos-container img{
    width: 100px;
}

.school-list-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.school-tab{
    width: 100%;
    margin: 10vh 0vh 0vh 0vh;
}

 .desktop-view {
    display: block;
    margin-bottom:5vh;
  }


.tab-container {
    width: 100%;
}

.tab-buttons {
  display: flex;
  justify-content: space-between; /* Change to flex-start for better control over overflow */
  margin-bottom: 10vh;
  background: #007AFF33;
  padding: 20px 10px 20px 10px;
  border-radius: 7px;
  overflow-x: auto; /* Allow horizontal overflow */
}

.tab-button {
  background: none;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  transition: 0.3s ease;
  border-radius: 10px;
  color: #007AFF;
  font-weight: 600;
  min-width: 150px; /* Set a minimum width for the buttons */
  flex-shrink: 0; /* Prevent buttons from shrinking */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0vh 2vh 0vh 2vh;
}


.tab-button.active {
    background-color:  #007AFF;
     color: #ffffff;
    box-shadow: 0 5px var(--gradiet-color);
    transform: translateY(4px);

}

.tab-content {
    position: relative;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    background: white; 
    height: 57vh;
  
}

.tab-content:before {
    content: "";
    position: absolute;
    top: -4px; /* Adjust according to border width */
    left: -4px; /* Adjust according to border width */
    right: -4px; /* Adjust according to border width */
    bottom: -4px; /* Adjust according to border width */
    border-radius: 10px;
    background: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
    z-index: -1; /* Ensure it is behind the main content */
}
#listDiv{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 5px;
    width: 100%;
    height: auto;
    align-items: center;
}
.list-box{
  width: 90%;
  padding: 5px 0px 5px 80px;
  border: 1px solid #007AFF;
  border-radius: 20px;
  margin: 1vh;
  display: flex;
  align-items: center;
position: relative;
}
.list-box:hover{
  background-color: #007AFF;
  color: white;
}
.list-box li{
   font-size: 10px;
   text-transform: uppercase;
   font-weight: 600;
   text-decoration: none;
}
.active-campus-container{
  width: 100%;
  padding: 5vh 0vh 2vh 0vh;
  display: flex;
}
.active-campus, .inactive-campus{
  display: flex;
  margin-right: 3vh;
  align-items: center;
}
.active-campus-box, .inactive-campus-box{
    width: 20px;
    height: 20px;
    background-color: #007AFF;
    margin-right: 2vh;
    border-radius: 5px;
}
.inactive-campus-box{
  background-color: red;
}



.icons{
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0vh 5vh 0vh 5vh;
    
}
span{
    font-size: 18px;
    text-decoration: none;
    color: #ffff;
    font-family: "Inter", sans-serif;
    margin: 0vh 0vh 0vh 5vh;
}



.get-event-container{
  width: 60%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5vh 0vh;
 
}
.get-event-button{
  padding: 15px;
  background-color: #007AFF;
  color: #ffffff;
  border-radius: 10px;
  border: 1px solid #007AFF;
  text-transform: uppercase;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}
.get-event-button:hover{
  background-color: #ffffff;
  color: #007AFF;
}

  


  .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    height: 50%;
  }
  
  .close-button {
    position: fixed;
    top: 70px;
    right: 25px;
    color: white;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .custom-prev-button, .custom-next-button {
    position: absolute;
    top: 50%;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    background:transparent;
    border: none;
    outline: none;
  }
  
  .custom-prev-button {
    left: 15px;
    color: #ffff;
    transform: translateY(-50%);
  }
  
  .custom-next-button {
    right: 15px;
    transform: translateY(-50%);
  }
  
  .modal-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .large-image {
    max-width: 100%;
   height: 60vh;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
  }

  #inactive-tooltip{
    background-color: white;
  }

  
  .popup-content {
    display: flex;
    flex-direction: column;

  }
  
  .popup-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
 
  
.announcement-container{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
}
.announcement{
  width: min(550px, 90%);
  aspect-ratio: 1;

}

.cancel-announcement{
  width: min(600px, 90%);
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 5vh;
}
/*MOBILE VIEW*/

@media only screen and (max-width: 1400px) {
    .logos-container {
      padding: 0 2vh;
    }
    .logos-container img{
        width: 12vw;
        max-width: 100px;
    }
    
  }



 

  


  @media only screen and (max-width: 850px) {
    .home-container{
        width: 100%;
        padding: 0vw 3vw;
        overflow: hidden;
    }

    .school-list-container h2 {
        font-size: 25px;
        margin-bottom: 2rem;
    }

    .logos-container {
      height: 10vh;
    }

   
     
    .school-details-text h2 {
        
        margin-bottom: 2vh;
        
    }

   

    .school-social-details {
        padding: 2vw;
    }

    .school-social-details div {
        margin: 2vw;
    }

    .divider {
        display: none;
    }
   
  }
@media only screen and (max-width: 768px){

  select {
    background-color: #007aff;
    width: 100%;
    padding: 3vh 5vh;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    color: #fff;
    border: 1px solid #828282;
    font-weight: 600;
  }
  option {
    background-color: #ffffff;
    width: 30%;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    color: #383838;
    border: 1px solid #828282;
    font-weight: 300;
  }
.mobile-content{
  width: 100%;
  height:40vh;
  padding: 15px;
  overflow-y: auto;
  border:2px solid #007AFF;
  border-radius: 10px;
  margin-bottom: 5vh;
}
.get-event-container{
  display: flex;
  flex-direction: column;
}
  .get-event-button{
    margin-top: 3vh;
  }
}
  @media only screen and (max-width: 500px){
    
    .school-exe{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .custom-prev-button{
    display: none;
  }
.custom-next-button {
  display: none;
}
  .statistic-table{
    width: 100%;
  }
  .see-more{
    margin-top: 3vh;
  }
  #listDiv{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 5px;
    width: 100%;
    height: auto;
    align-items: center;
}
  .list-box{
    width: 100%;
    margin: 0;
    padding: 5px 0px 5px 20px;
  }
  .get-event-button{
    padding: 10px;
    font-size: 15px;
  }

}
  