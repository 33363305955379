.campus-main-container{
    width: 100%;
    padding: 0vh 8vh 0vh 8vh;
    overflow: hidden;
    
}
.campus-container{
    width: 100%;
    height: auto; 
}
.hero-section{
   width: 100%;
   display: flex;
   height: auto;
   padding: 20px ;
   background-image: url('../../Images/logo1.png');
   background-size: 50%; /* Adjust as needed */
   background-position: center; /* Adjust as needed */
   background-repeat: no-repeat;
 
}
.loader-container{
  width: 100%;
  height: 80.5vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inactive-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10vh 0vh;
  height: 100%;
}
.inactive-headtext{
  font-size: 20px;
  font-weight: 600;
  color: #00000000;
  margin: 3vh;
}
.inactive-text{
 font-weight: 600;
 font-size: 15px;
 text-align: center;
 line-height: 6vh;
}
.campus-hero-container{
  width: 100%;
  height: 100%;
  background:rgba(255, 255, 255, 0.8) ;
  padding-bottom: 20px;
}
.campus-hero{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.campus-hero-one, .campus-hero-two{
    width: 50%;
    height: 70vh;
}
.campus-hero-one{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.campus-hero-one h1{
font-family: Abhaya Libre ExtraBold;
font-weight: 800;
line-height: 60.6px;
color: black;
text-align: left;
font-size: 55px;
}
.campus-hero-one p{
 
    margin-top: 3vh;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 37.8px;
    text-align: left;

    
}


.campus-hero-two{
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative; 
}

.ty{
    width: 85%;
    height: 85%;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
}

.slider-button-container{
  margin-top: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slided-container{
  width: 100%;
  height: 100%;
}
.embla {
    overflow: hidden;
    width: 100%;
    height: 100%;
    
  }
  .embla__container {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .embla__slide {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10pz;

  }
  .embla__slide-img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .embla__dot{
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 30px;
    height: 6px;
    margin: 0 5px;
    cursor: pointer;
    padding: 0;
    outline: none;
    transition: background 0.3s ease, border-color 0.3s ease;
  }
 .embla__dot--selected{
    background-color: #007AFF;
  }
  .alumni-button-container{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
  }
  .alumni-button{
    padding: 2vh 10vh;
    border: 5px solid #007AFF;
    border-radius: 10px;
    background-color: transparent;
    font-size: 15px;
    color: #007AFF;
    font-weight: 700;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
  }
  .alumni-button:hover{
    background-color: #007AFF;
    color: #ffffff;
    
  }
  .campus-exe-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 10vh 0vh;
 
  }
  .campus-exe-container::before{
    content: "";
    position: absolute;
    top: 0;
    left: -30%;
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url('../../Images/logo1.png');
    background-size: 70%; /* Adjust as needed */
    background-position: left; /* Adjust as needed */
    background-repeat: no-repeat;
    opacity: 0.1; /* 10% opacity */
    z-index: -1;
    overflow-y: hidden;
  }
  .exe-container{
    width: 100%;
    padding: 20px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .exe-details-container-one, .exe-details-container-two{
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px 0px #00000040;
    display: flex;
align-items: center;
  }
  .exe-details-container-two{
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    margin-top: 4vh;
  }
  .exe-image {
    width: 30%;
    min-width: 35vh;
    border-radius: 10px;
    height: 30vh; 
    background-color: #ffff;
    border-radius: 20px;
    padding: 5px;
    background: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
    
}
.exe-image-container{
    width: 100%;
    height: 100%;
     border-radius: 20px;
     background-color: white;
     position: relative;
}
.overlay-content {
    width: 70%;
    height: 35px;
    background-color: #0172DA;
    position: absolute;
    bottom: 15px;
    left: 0px; 
    border-radius: 0px 19.4px 19.4px 0px;
    z-index: 1; 
    color: #ffffff; 
}
.exe--details{
  width: auto;
    display: flex;
    padding: 10px;
    flex-direction: column;
   justify-content: center;
   
}
.exe--details h6{
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    margin: 1vh;
   
}
.font-bold{
  font-weight: 600;
}
.campus-socialmedia-container{
  width: 100%;
 padding: 10px 0px;
 display: flex;
 justify-content: center;
 align-items: center;
}
.campus-socialmedia{
  width: 80%;
 height: 100px;
 display: flex;
 justify-content: center;
 align-items: center;
}
.email-icon-container{
    width: 70px;
    height: 70px;
    background: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 2px;
    
}
.icon{
   display: flex;
  
}
.social-container-one{
  width: 40%;
  height: 50px;
  background: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
  display: flex;
  align-items: center;
  border-top-right-radius:30px;
  border-bottom-right-radius:30px;
}
.social-container-two{
  width: 40%;
  height: 50px;
  background: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius:30px;
  border-bottom-left-radius:30px;
  padding-left: 20px;
}
.icon a{
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin: 3px;
}
.phone-number-container{
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
  border-radius: 50px;
}
.phone-number-container a{
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;

}
.email-link{
  font-size: 17px;
  color: #ffffff;
  margin-left: 1vh;
}
.afcf-texts {
  color: white;
  font-size: 17px
}
.email-icon{
   width: 100%;
   height: 100%;
   display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
}
.flyer-title{
  width: 100%;
  height:fit-content;
  display: flex;
  align-items: center; 
  
}
.flyer-title h2{
  margin: 0;
  font-size: clamp(1rem, 2.5vw, 2rem);
}
.dot{
  width: 20px;
  height: 20px;
  background: linear-gradient(90deg, #007AFF 0%, #2FE6F2 23.5%, #599CE6 44.5%, #CEA3F9 72.5%, #004999 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 2vh;
}
.indot{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
}
.other-programs-container{
  margin: 5vh 0vh;
}
.other-program{
  width: 70%;
  height: auto;
  border: 1px solid #007AFF;
  border-radius: 10px;
  padding: 20px;
}
.campus-details-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10vh 0vh;
  height: auto;

}
.campus-details-container::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url('../../Images/logo1.png');
  background-size: 70%; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;
  opacity: 0.1; /* 10% opacity */
  z-index: -1;
}

.program-table {
  border-collapse: collapse;
  width: 100%;
  margin: 30px 0;
  font-size: 0.9em;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.program-table th, .program-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px 15px;
}

.program-table th {
  background-color: #007AFF;
  color: #ffffff;
}

.program-table td {
  border-bottom: 1px solid #dddddd;
}

.program-table td:nth-of-type(even) {
  background-color: #f3f3f3;
}

.program-table td:last-of-type {
  border-bottom: 2px solid #007AFF;
}
.styled-table {
  border-collapse: collapse;
  width: 100%;
  margin: 30px 0;
  font-size: 0.9em;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 
}
.statistic-table{
  width: auto;
}

.styled-table th, .styled-table td {
  border: 2px solid #007AFF;
  text-align: left;
  padding: 12px 50px;
}

.styled-table th {
  background-color: #007AFF;
  color: #ffffff;
}

.styled-table td {
  border-bottom: 2px solid #007AFF;
}
.styled-table td:nth-of-type(even) {
  background-color: #ffffff;
}

.styled-table td:last-of-type {
  border-bottom: 2px solid #007AFF;
}

.uploads-container{
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-button{
  border: 2px solid black;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:6px 35px;
}
.download-button{
  border: 2px solid black;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px 20px;
}
.download-button h4{
  font-size: 15px;
  font-weight: 600;
  margin: 1vh;
}

.upload-button h4{
  font-size: 15px;
  font-weight: 600;
  margin: 1vh;
}

.upload-button-container{
  width: 192px;
 
}
.upload-button-container p{
  font-size: 10px;
  font-weight: 700;
}
.images{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.sliders-container{
  margin-top: 10vh;
}


 
.edit-button-container{
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 3vh 0vh 3vh 8vh;
   cursor: pointer;
 }
 .edit-container{
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
}
 .add-image{
  width: 70%;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
 }
 .edit-icon{
  color: #007AFF;
 }

label{
  display: flex;
  flex-direction: column;
  font-size: 15px;
  margin-top: 2vh;
}
.input-acronym{
  width: 50%;
  padding: 10px;
  margin: 2vh 0vh 2vh 0vh;
  border-radius: 7px;
}
 :root {
  --base-duration: 500ms;
  --color-1: #2c3e50;
  --color-2: #3498db;
  --color-3: #2ecc71;
}

*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}


.hide {
  display: none;
}

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 12.5rem;
  margin-top: 1vh;
  padding: 1.5rem 3.125rem;
  background-color: var(--color-2);
  border: none;
  border-radius: 0.3125rem;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  color: white;
  font-weight: 300;
  text-transform: uppercase;
  transition: var(--base-duration) ease;
  overflow: hidden;
  cursor: pointer;
 
}

.button::before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #39d982; /* lighten(var(--color-3), 10%) */
}

.button span {
  position: absolute;
  line-height: 0;
}

.button span i {
  transform-origin: center center;
}

.button span:nth-of-type(1) {
  top: 50%;
  transform: translateY(-50%);
}

.button span:nth-of-type(2) {
  top: 100%;
  transform: translateY(0%);
  font-size: 24px;
}

.button span:nth-of-type(3) {
  display: none;
}
.google-drive-button{
  display: flex;
  justify-content:space-between;
}


.active::before {
  width: 100%;
  transition: width 3s linear;
}

.active span:nth-of-type(1) {
  top: -100%;
  transform: translateY(-50%);
}

.active span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
}

.active span:nth-of-type(2) i {
  animation: loading var(--base-duration) linear infinite;
}

.active span:nth-of-type(3) {
  display: none;
}

.finished {
  background-color: #39d982; /* lighten(var(--color-3), 10%) */
}

.finished .submit {
  display: none;
}

.finished .loading {
  display: none;
}

.finished .check {
  display: block !important;
  font-size: 24px;
  animation: scale 0.5s linear;
}

.finished .check i {
  transform-origin: center center;
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(10);
  }
  50% {
    transform: scale(0.2);
  }
  70% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}
input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #007AFF;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}



.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8); /* Background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  border-radius: 10px;
  position: relative;
   max-width: 90%;
  height:90%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.zoom-container {
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
}

.zoom-image {
  display: block;
  width: 100%;
  transition: transform 0.2s ease;
}

.zoomed-in .zoom-image {
  transform: scale(2); /* Adjust scale as needed */
  cursor: zoom-out;
}


@media only screen and (max-width: 768px){
  .other-program{
    width: 100%;
    margin-top: 2vh;
  }
  .others{
    overflow-y: auto;
    height: 40vh;
    width: 100%;
  }
}
@media only screen and (max-width: 880px){
  .campus-main-container{
    width: 100%;
    padding: 0vh 1vh 0vh 1vh;
    overflow: hidden;
    
}
    .campus-hero{
      display: flex;
      flex-direction: column;
    }
    
   
    .campus-hero-one, .campus-hero-two, .ty{
      width: 100%;
     height: auto;
    }
    .campus-hero-two{
      margin: 8vh 0vh;
    }
    .exe-container {
      padding: 5vw 0;
    }
    .exe-details-container-one{
      width: auto;
    } .exe-details-container-two{
          width: auto;
          margin-left: 0;
    }
    .exe--details{
      width: auto;
      margin: 3vh;
      padding: 3px;
  }




  .campus-socialmedia-container {
  padding: 0vh 5vh;
    height: 100%;
  }
    .campus-socialmedia{
    display: flex;
    width: 100%;
    padding: 2px;
    }
    

  
    .afcf-texts {
      font-size: 13px;
    
    }
    .email-link{
      font-size: 13px;
    }

  

    .t{
      height: 60vh;
    }
    .edit-button-container{
       
      padding: 3vh 0vh 3vh 4vh;
    
     }
     .event-button{
      width: 100%;
     }
     .button{
      width: 100%;
     }
     .drop-container{
      width: 100%;
      padding: 10px;
     }
     .input-acronym{
      width: 100%;
     }
     .add-image{
      width: 90%;
     }
     input[type=file]::file-selector-button {
     margin-left: 40px;
  }
  .campus-exe-container{
    padding: 0;
  }
  .modal-content {
    height: auto;
  }
}

@media only screen and (max-width: 500px){
  .exe-details-container-one{
    display: flex;
    flex-direction: column;
  } .exe-details-container-two{
    display: flex;
    flex-direction: column-reverse;
  }
  .campus-hero-one h1 {
    font-family: Abhaya Libre ExtraBold;
    font-weight: 800;
    line-height: 40px;
    color: black;
    text-align: left;
    font-size: 30px;
}
.campus-hero-one p{
  font-size: 15px;
  line-height: 20px;
}
  .slided-container{
    width: 100%;
    height: 35vh;
  }
  .exe-image{
    width: 100%;
    height: 45vh;
  }
  .exe--details{
    width: 100%;
  }
  .exe--details h6{
    text-decoration: none;
    text-transform: uppercase;
   margin: 3px;
  }
  .campus-socialmedia-container {
    margin-top: 2vh;
    width: 100%;
    height: 100%;
  }
    .campus-socialmedia{
    display: flex;
    flex-direction: column;
    padding: 2px;
    width: 100%;
    
    }
    .social-container-one, .social-container-two{
      width: 100%;
      margin: 2vh 0vh;
    }
    .styled-table th, .styled-table td {
      border: 2px solid #007AFF;
      text-align: left;
      padding: 12px 12px;
    }
    .styled-table th {
      padding: 0px 20px;
    }
    .uploads-container{
      display: flex;
      flex-direction: column;
      margin-top: 2vh;
    }
    .upload-button-container, .upload-button, .download-button{
     width: 100%;
     margin: 1vh 0vh;
    }
    
  }