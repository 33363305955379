@keyframes example {
    0%   {left:0px; top:0px;}
    25%  {left:-100px; top:0px;}
    50%  {left:100px; top:0px;}
    75%  {left:-50px; top:00px;}
    100% {left:0px; top:0px;}
  }



.home-container {
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
  
  .home-container.animate {
    opacity: 0;
  }
  
  .logos-container img {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
    position: relative;
    animation-name: example;
    animation-duration: 3s;
  }
  
.logos-container {
    transform: translateX(100%);
    transition: transform 1s ease-in-out;
}
.logos-container.animate {
    transform: translateX(0);
}

  .logos-container img:hover {
    transform: scale(1.2);
  }
  
  .school-list-container {
    transform: translateX(-100%);
    transition: transform 1s ease-in-out;
  }
  
  .school-list-container.animate {
    transform: translateX(0);
  }
  
  .school-details {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .school-details.animate {
    opacity: 1;
  }
  
  .school-social-details {
    opacity: 0;
    transition: opacity 1s step-start;
  }
  
  .school-social-details.animate {
    opacity: 1;
  }
  

  .top-home-section {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .top-home-section.animate {
    opacity: 1;
  }
  
  #listDiv {
   
    transition: opacity 0.5s ease-in-out;
  }
  
  .tab-content-enter {
    opacity: 0;
  }
  
  .tab-content-enter-active {
    opacity: 1;
  }
  
  .tab-content-exit {
    opacity: 1;
  }
  
  .tab-content-exit-active {
    opacity: 0;
  }
  





  /* src/App.css */

.campus-selector button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.campus-selector button:hover {
  background-color: #0056b3;
}

.image-gallery {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.image-gallery img {
  width: 100%;
  max-width: 150px;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.2s;
}

.image-gallery img:hover {
  transform: scale(1.05);
}

.full-screen-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
 padding: 10vh;
  overflow-y: auto;
  z-index: 1;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  width: 100%;
  margin: 20px auto;
}



.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

.image-modal img {
  width: 100%;
  max-width: 80%;
  max-height: 80%;
}

.image-modal button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.images-item-img{
  width: 100%;
  border-radius: 10px;
}
@media (min-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .image-modal img {
    max-width: 60%;
  }
}

@media (min-width: 1024px) {
  .image-gallery img {
    max-width: 200px;
  }
}
